<template>
  <TAbout />
</template>

<script>
import TAbout from "@/components/templates/About.vue";

export default {
  components: {
    TAbout
  },
  name: "PAbout"
};
</script>
