<template>
  <ul
    style="background-color: #5C56A2 !important;"
    class="m-accordion has-text-centered-mobile has-text-centered-tablet has-text-left-desktop"
  >
    <div
      style="color: white; text-align: center; padding-bottom: 25px; font-weight: 700; font-size: 33px;"
    >
      Dúvidas Frequentes
    </div>
    <li
      v-for="item in items"
      class="m-accordion__item"
      :class="{ 'is-active': item.id === activeItem }"
      :key="item.id"
    >
      <h4
        class="m-accordion__item__title is-size-5-mobile is-size-6-desktop"
        @click="() => open(item.id)"
        v-html="item.title"
      ></h4>
      <AContent
        class="m-accordion__item__body is-size-6-mobile is-size-6-tablet"
        style="margin-top: -27px !important;"
        v-html="item.body"
      />
    </li>
  </ul>
</template>

<script>
import { ref } from "vue";
import AContent from "@/components/atoms/Content.vue";

export default {
  name: "MAccordion",
  components: {
    AContent
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    const activeItem = ref("");

    return {
      activeItem
    };
  },
  methods: {
    open(item) {
      this.activeItem = item;
    }
  }
};
</script>

<style lang="scss">
.m-accordion {
  position: relative;

  &__item {
    &.is-active {
      .m-accordion {
        &__item {
          &__body * {
            opacity: 1;
          }

          &__body {
            color: $white;
            height: auto;
            max-height: 100%;
            padding-bottom: 3rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: 3rem;
          }
        }
      }
    }

    &__title {
      background-color: $white;
      border-radius: 7px;
      box-shadow: 0 10px 22px #00000016;
      padding-left: 50px !important;
      color: #5e59a3 !important;
      font-weight: 600 !important;
      cursor: pointer;
      margin-top: -6px;
      padding: 3rem;
      position: relative;
      z-index: 2;
    }

    &__body,
    &__body * {
      @extend %transition-default;
      height: 0;
    }

    &__body {
      background-color: white;
      color: #5e59a3 !important;
      font-weight: 600;
      line-height: pxToEm(22px);
      margin-left: auto;
      margin-right: auto;

      max-height: 0;
      overflow: hidden;
      padding-bottom: 28px;
      padding-left: 50px !important;
      padding-right: 50px !important;
      width: 100%;

      * {
        display: inline-block;
        opacity: 0;
      }
    }
  }
}

@include media(">=desktop") {
  .m-accordion {
    &__item {
      &__title {
        padding: 1.2rem;
      }

      &__body {
        line-height: pxToEm(26px);
      }
    }
  }
}
</style>
