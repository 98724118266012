<template>
  <div class="t-about wrapper" id="wrapper">
    <OHeader />
    <OMainAbout />
    <OFooter showContactBlock />
  </div>
</template>

<script>
import OFooter from "@/components/organisms/Footer.vue";
import OHeader from "@/components/organisms/Header.vue";
import OMainAbout from "@/components/organisms/MainAbout.vue";

export default {
  name: "TAbout",
  components: {
    OFooter,
    OHeader,
    OMainAbout
  }
};
</script>

<style lang="scss">
body {
  padding: 0 !important;
  margin: 0 !important;
  // overflow-x: hidden !important
}

.page-sobre-o-programa {
  // background: transparent radial-gradient(closest-side at 80% 4%, #2D9CB9 0%, #5C56A2 100%);
  // background: transparent radial-gradient(closest-side at 62% 7%, #2D9CB9 12%, #5C56A2 140%);

  color: $lochmara;

  // .area-slider__slide>.area-slider__slide__img {
  //   background-image: none !important;
  // }

  .text-programa-participantes {
    color: white !important;
  }

  // .hedsetIcon {
  //   background-image: url("../../assets/white-headset.svg") !important;
  // }

  .textFaleConosco {
    color: #70cded !important;
  }

  .cardContato {
    border: 1px solid #70cded !important;

    .numberContato {
      color: #70cded !important;
    }
  }

  .header-section::before {
    background-image: none !important;
  }

  // .footer {
  //   background-color: #5C56A2;
  // }

  p {
    line-height: pxToEm(26px);
  }
}

.b-programs {
  z-index: 6;
}

.t-about {
  #header {
    background: none;
    position: absolute !important;

    .columns {
      margin-bottom: 0;
    }

    .a-logo-bottom {
      img {
        max-width: 300px;
        width: 64%;
      }
    }
  }

  .m-section-title__title {
    font-weight: 600;
    color: #70cded !important;
  }

  .m-video {
    &::before {
      @extend %position-absolute;
      @extend %pseudo-el;
      background: rgb(41, 136, 195);
      background: radial-gradient(
        circle,
        rgba(41, 136, 195, 1) 0%,
        rgba(24, 100, 147, 1) 100%
      );
      display: block;
      height: calc(100% + 1px);
      opacity: 0.55;
      pointer-events: none;
      width: 100%;
      z-index: 2;
    }

    &.is-playing {
      &::before {
        display: none;
      }
    }
  }

  .o-about {
    background-color: #0275bf;
    border-radius: 10px;
    color: $white;

    .play {
      @extend %position-absolute;
      height: 60px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
    }

    .m-video {
      box-shadow: 0 20px 30px #00000030;
    }
  }

  .m-video {
    margin: 0 auto;
  }
}

.b-footer-contact {
  .m-section-title__title {
    color: #0275bf;
  }
}

@media (min-width: 800px) and (min-height: 600px) and (orientation: landscape) {
  .t-about {
    #header {
      // height: 30vh;
    }
  }
}

@include media(">=tablet") {
  .t-about {
    #header {
      // height: 18vh;

      .logo-bottom-wrapper {
        display: none;
        bottom: 40px;

        img {
          max-width: 230px;
        }
      }
    }

    .o-about {
      margin-left: auto;
      margin-right: auto;
      max-width: 1334px;
      padding-left: 4rem !important;
      padding-right: 4rem !important;
      width: 80%;

      .play {
        height: 80px;
        width: 80px;
      }
    }
  }
}

@include media(">=desktop") {
  .t-about {
    #header {
      // height: 52vh;

      .logo-bottom-wrapper {
        pointer-events: none;
        position: initial;
        z-index: -1;
      }
    }

    .b-about {
      margin-top: -34vh;
      z-index: 5;

      .m-section-title__title {
        color: $white;
      }
    }

    .o-about {
      padding-left: 6rem !important;
      padding-right: 6rem !important;

      .play {
        height: 100px;
        width: 100px;
      }
    }
  }
}

@include media(">=widescreen") {
  .t-about {
    .o-about {
      padding-left: 7rem !important;
      padding-right: 7rem !important;
    }

    .b-about {
      margin-top: -38vh;

      .o-about {
        padding-bottom: 14rem !important;
      }
    }
  }
}

@include media(">=fullhd") {
  .t-about {
    .o-about {
      padding-left: 12rem !important;
      padding-right: 12rem !important;
    }
  }
}
</style>
