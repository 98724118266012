<template>
  <AContent class="c-references has-text-left mt-2">
    <p style="color: white !important;" v-if="showTitle">Referências:</p>
    <ol class="mb-0 ml-2 mt-0">
      <slot />
    </ol>
  </AContent>
</template>

<script>
import AContent from "@/components/atoms/Content.vue";

export default {
  components: {
    AContent
  },
  props: {
    showTitle: {
      default: true,
      required: false,
      typeof: Boolean
    }
  }
};
</script>

<style lang="scss">
.c-references {
  li,
  p {
    color: #b1b1b1;
  }

  p {
    font: normal normal 700 14px/14px Montserrat;
    letter-spacing: -0.23px;
    margin-bottom: 4px !important;
  }

  li {
    font: normal normal normal 11px/14px Montserrat;
    letter-spacing: -0.15px;
  }
}

@media (min-width: 1408px) {
  .c-references-container {
    max-width: 74% !important;
  }
}
</style>
