<template>
  <div class="m-video" :class="{ 'is-playing': showControls }">
    <div
      class="m-video__play"
      :class="{ 'is-hidden': showControls }"
      @click="playVideo"
    >
      <slot name="playButton" />
    </div>
    <video class="m-video__video" ref="video" :controls="showControls">
      <slot />
    </video>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "MVideo",
  props: {
    controls: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props) {
    const showControls = ref(props.controls);
    const video = ref(null);
    const wasPlayPressed = ref(false);

    return {
      showControls,
      video,
      wasPlayPressed
    };
  },
  methods: {
    playVideo() {
      this.showControls = true;
      this.$refs.video.play();
    }
  }
};
</script>

<style lang="scss">
.m-video {
  position: relative;

  &__play {
    @extend %position-absolute;
    @extend %zero-position;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  &__video {
    vertical-align: top;
  }
}
</style>
