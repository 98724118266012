<template>
  <div class="m-tabs is-inline-block" ref="tabsContainer">
    <div class="m-tabs__track">
      <ul>
        <li
          v-for="tab in tabs"
          :class="{ 'is-active': activeTab === tab.id }"
          :id="`tab-${tab.id}`"
          :key="tab.id"
        >
          <a
            href=":javascript;"
            @click.prevent="() => setSpotPosition(tab.id, tab.action)"
            :title="tab.title"
          >
            <span v-html="tab.title" />
          </a>
        </li>
      </ul>
      <div class="m-tabs__spot" ref="spot"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import $ from "jquery";

export default {
  name: "MTabs",
  props: {
    activeTab: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const spot = ref(null);
    const tabsContainer = ref(null);

    onMounted(() => {
      setTimeout(() => setSpotPosition(props.activeTab), 500);
      $(window).on("resize", () => setSpotPosition(props.activeTab));
    });

    watch(
      () => props.activeTab,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          setSpotPosition(newValue);
        }
      }
    );

    const setSpotPosition = (tabId, callback = () => {}) => {
      callback();

      const $tabsContainer = $(tabsContainer.value);
      const $activeTab = $tabsContainer.find(`#tab-${tabId}`);
      let activeTabPosition = $activeTab.position().left;

      if ($activeTab.is(":first-of-type") || $activeTab.is(":last-of-type")) {
        activeTabPosition += 4;
      }

      $(spot.value)
        .css({ left: `${activeTabPosition}px` })
        .width($activeTab.width() - 8);

      $tabsContainer
        .stop()
        .animate({ scrollLeft: activeTabPosition - 4 }, 500, "swing");
    };

    return {
      setSpotPosition,
      spot,
      tabsContainer
    };
  }
};
</script>

<style lang="scss">
.m-tabs {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  &__track {
    position: relative;

    ul,
    li {
      display: flex;
    }

    ul {
      align-items: center;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: flex-start;
      padding: 0;
      position: relative;
      z-index: 1;
    }

    li {
      flex-basis: 0;
      flex-grow: 1;

      a {
        width: 100%;
      }
    }
  }
}
</style>
