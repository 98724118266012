<template>
  <AColumns
    class="m-emphasis is-centered is-mobile is-multiline is-vcentered mb-3 mt-3 pb-2 pl-3 pr-3 pt-2"
  >
    <AColumn
      class="m-emphasis__first has-text-centered is-4-mobile is-4-tablet is-5-desktop"
    >
      <slot name="first" />
    </AColumn>
    <AColumn class="m-emphasis__second has-text-centered">
      <slot name="second" />
    </AColumn>
  </AColumns>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";

export default {
  name: "MEmphasis",
  components: {
    AColumn,
    AColumns
  }
};
</script>

<style lang="scss">
.m-emphasis {
  border: 1px solid $white;
  border-radius: 9px;
  position: relative;

  &__first {
    font-size: 5rem;

    img {
      min-width: 40px;
    }
  }

  &__second {
    font-size: 1.2rem;
  }
}

@include media(">=425px") {
  .m-emphasis {
    &__first {
      font-size: 6rem;

      img {
        min-width: 80px;
      }
    }

    &__second {
      font-size: 1.6rem;
    }
  }
}

@include media(">=tablet") {
  .m-emphasis {
    &__first {
      font-size: 3rem;

      img {
        margin-top: 18px;
        min-width: initial;
        max-width: 43px;
      }
    }

    &__second {
      font-size: 1rem;
    }
  }
}

@include media(">=desktop") {
  .m-emphasis {
    &__first {
      font-size: 4rem;

      img {
        max-width: 62px;
      }
    }

    &__second {
      font-size: 1.1rem;
    }
  }
}
</style>
